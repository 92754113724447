import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import QuestionSection from "../components/QuestionSection";
import NavigationButton from "../components/NavigationButton";
import bgred from "../assets/bgred.svg";
import smudgeDotRedIcon from "../assets/smudgeDotRed.svg";
import AnswersContext from "../context/AnswersContext";
import RootContainer from "../components/RootContainer";

const UXConsistency = () => {
  const { answers, saveAnswer } = useContext(AnswersContext);

  const questions1 = [
    {
      id: "UXConsistency1",
      text: "Do the appearance and behaviour of UI elements reflect the conventions of the platform?",
    },
    {
      id: "UXConsistency2",
      text: "Consider how frequently users will access the experience: the less frequent the usage the more you should leverage platform conventions.",
    },
    {
      id: "UXConsistency3",
      text: "Does your interface match user expectations of speed and fluidity (e.g. loading data, animations)?",
    },
  ];

  const questions2 = [
    {
      id: "UXConsistency4",
      text: "Are UI elements (e.g. fonts, colours etc) in harmony with each other and consistent at every touch point? Are you following a style guide? If so, be mindful of balancing your brand requirements with the user's expectations of the platform.",
    },
    {
      id: "UXConsistency5",
      text: "Are UI and navigation elements used for a clear and consistent purpose throughout the experience?",
    },
  ];

  const questions3 = [
    {
      id: "UXConsistency6",
      text: "Does your interface leverage what users believe they already know about how it should behave? (e.g. pushing down a pedal in a car implies “more”, pinching fingers on mobile implies “zoom”).",
    },
    {
      id: "UXConsistency7",
      text: "For every interaction, consider the trade-off between leveraging an existing metaphor and introducing a new one that users will need to learn.",
    },
  ];

  const handleInputChange = (id, value) => {
    saveAnswer(id, value);
  };

  return (
    <RootContainer backgroundImage={bgred}>
      <Typography
        variant="h4"
        style={{
          fontWeight: "bold",
          marginBottom: "24px",
          fontSize: "32px",
          lineHeight: "1.2",
        }}
      >
        <img
          src={smudgeDotRedIcon}
          alt="Smudge Dot Red Icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
        />
        Consistency
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: "24px",
          fontSize: "18px",
          lineHeight: "1.6",
        }}
      >
        Match the conscious and subconscious expectations of the user.
      </Typography>

      <QuestionSection
        title="Platform"
        description="Ensure consistency and leverage familiarity with the platform you're designing for. This will reduce friction and increase the productivity of the user."
        questions={questions1}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection
        title="Solution"
        description="Ensure consistency within the experience you're creating. For example, design language, user interactions and interface elements should be consistent."
        questions={questions2}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection
        title="Mental Models"
        description="Ensure consistency with the most prevalent “mental models”. Where possible, help users better understand new concepts by leveraging metaphors for familiar experiences."
        questions={questions3}
        answers={answers}
        onInputChange={handleInputChange}
      />

      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <NavigationButton
          direction="previous"
          text="Previous"
          link="/user-experience"
          description="User Experience - Overview"
        />
        <NavigationButton
          direction="next"
          text="Next"
          link="/user-experience-communication"
          description="User Experience - Communication"
        />
      </Box>
    </RootContainer>
  );
};

export default UXConsistency;
