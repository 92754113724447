import React, { useState, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import nextHollow from '../assets/nextHollow.svg';
import nextSolid from '../assets/nextSolid.svg';
import previousHollow from '../assets/previousHollow.svg';
import previousSolid from '../assets/previousSolid.svg';
import nextHollowDark from '../assets/nextHollowDark.svg';
import nextSolidDark from '../assets/nextSolidDark.svg';
import previousHollowDarkV2 from '../assets/PreviousHollowDarkV2.svg';
import previousSolidDark from '../assets/previousSolidDark.svg';
import { ThemeContext } from '../App';

const NavigationButton = ({ direction, link, description }) => {
  const [hover, setHover] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);
  const isNext = direction === 'next';

  const getIcon = () => {
    if (isNext) {
      return hover ? (isDarkMode ? nextHollowDark : nextSolid) : (isDarkMode ? nextSolidDark : nextHollow);
    } else {
      return hover ? (isDarkMode ? previousHollowDarkV2 : previousSolid) : (isDarkMode ? previousSolidDark : previousHollow);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      margin='40px'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Box
          display="flex"
          alignItems="center"
          style={{
            cursor: 'pointer',
            padding: '8px',
            borderRadius: '8px',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          <img src={getIcon()} alt={isNext ? "Next" : "Previous"} style={{ width: '256px' }} />
        </Box>
      </Link>
      <Typography variant="body2" style={{ marginTop: '4px', color: '#666', fontSize: '16px' }}>
        {description}
      </Typography>
    </Box>
  );
};

export default NavigationButton;
