import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import QuestionSection from '../components/QuestionSection';
import NavigationButton from '../components/NavigationButton';
import bgyellow from '../assets/bgyellow.svg';
import smudgeDotYellowIcon from '../assets/smudgeDotYellow.svg';
import AnswersContext from '../context/AnswersContext';
import RootContainer from '../components/RootContainer';

const BusinessProcessesAndTools = () => {
  const { answers, saveAnswer } = useContext(AnswersContext);

  const questions1 = [
    { id: "BusinessProcesses1", text: "What overarching design and/or development methodology do you use when undertaking a project? Do you need one?" },
    { id: "BusinessProcesses2", text: "Is your methodology appropriate for the type and size of your team, project or customer?" },
    { id: "BusinessProcesses3", text: "Is the project team set up for success? Are roles and responsibilities sufficiently clear?" }
  ];

  const questions2 = [
    { id: "BusinessProcesses4", text: "What tools and systems do you use to forecast and track progress, time and costs? What is the process for communicating changes?" },
    { id: "BusinessProcesses5", text: "What tools, meetings and forums do you use to communicate? Do the tools have sufficient capability and flexibility? Do the meetings have a clear purpose and cadence?" },
    { id: "BusinessProcesses6", text: "How do you capture learnings and hindsights during / after a project? What is the process for flowing those insights back into your process?" }
  ];

  const questions3 = [
    { id: "BusinessProcesses7", text: "Who is involved in the quality, review and approval process? What is the workflow? Are roles and responsibilities sufficiently clear?" },
    { id: "BusinessProcesses8", text: "Is the review process working? Are the best ideas rising to the top? How could this be improved?" },
    { id: "BusinessProcesses9", text: "Who makes the final decision on if/when a product or feature ships? Is the approval process sufficiently clear?" }
  ];

  const handleInputChange = (id, value) => {
    saveAnswer(id, value);
  };

  return (
    <RootContainer backgroundImage={bgyellow}>
      <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '24px', fontSize: '32px', lineHeight: '1.2' }}>
        <img src={smudgeDotYellowIcon} alt="Smudge Dot Yellow Icon" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
          Processes and Tools
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '24px', fontSize: '18px', lineHeight: '1.6' }}>
          What processes and tools do you need to execute successfully?
      </Typography>

      <QuestionSection title="Methodology" questions={questions1} answers={answers} onInputChange={handleInputChange} />
      <QuestionSection title="Project Management" questions={questions2} answers={answers} onInputChange={handleInputChange} />
      <QuestionSection title="Review and Approval" questions={questions3} answers={answers} onInputChange={handleInputChange} />

      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <NavigationButton direction="previous" text="Previous" link="/business-capability" description="Business Considerations - Capability" />
        <NavigationButton direction="next" text="Next" link="/technical-considerations" description="Technical Considerations - Overview" />
      </Box>
    </RootContainer>
  );
};

export default BusinessProcessesAndTools;
