import React, { useContext } from 'react';
import { Typography, TextField, Box, Paper } from '@mui/material';
import { ThemeContext } from '../App';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// This is for changing all border colour to section theme colour
// const StyledTextField = styled(TextField)(({ theme, currentColor }) => ({
//   '& .MuiOutlinedInput-root': {
//     '& fieldset': {
//       borderColor: currentColor,
//     },
//     '&:hover fieldset': {
//       borderColor: currentColor,
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: currentColor,
//     },
//   },
// }));

// This is for changing all border colour to section theme colour on when selected 
const StyledTextField = styled(TextField)(({ theme, currentColor }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)', // Light and transparent grey
    },
    '&:hover fieldset': {
      borderColor: 'black', // Hover color
    },
    '&.Mui-focused fieldset': {
      borderColor: currentColor, // Focused color
    },
  },
}));

const QuestionSection = ({ title, description, questions, answers, onInputChange }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const location = useLocation();

  // Define the colors for each section
  const sectionColors = {
    '/project-evaluation': '#FBC345', // Yellow
    '/business-capability': '#FBC345', // Yellow
    '/business-processes-and-tools': '#FBC345', // Yellow
    '/technical-capability': '#4747AA', // Blue
    '/technical-sustainability': '#4747AA', // Blue
    '/user-experience-consistency': '#EE6968', // Red
    '/user-experience-communication': '#EE6968', // Red
    '/user-experience-flexibility': '#EE6968', // Red
  };

  // Get the current color based on the path
  const currentColor = Object.keys(sectionColors).find((path) =>
    location.pathname.includes(path)
  )
    ? sectionColors[Object.keys(sectionColors).find((path) => location.pathname.includes(path))]
    : '#000000'; // Default color if no match is found

  return (
    <Paper
      elevation={3}
      style={{
        padding: '20px',
        borderRadius: '12px',
        backgroundColor: isDarkMode ? 'rgba(66, 66, 66, 0.9)' : 'rgba(255, 255, 255, 0.7)',
        marginBottom: '24px',
        color: isDarkMode ? '#FFFFFF' : '#000000',
      }}
    >
      <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
        {title}
      </Typography>
      {description && (
        <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '20px', marginTop: '8px'}}>
          {description}
        </Typography>
      )}
      {questions.map((question, index) => (
        <Box key={question.id} marginBottom={3}>
          <Typography variant="body1" style={{ marginBottom: '8px' }}>
            {question.text}
          </Typography>
          <StyledTextField
            fullWidth
            variant="outlined"
            placeholder="Enter your observation here. Write as much as you like."
            multiline
            rows={3}
            currentColor={currentColor}
            style={{
              backgroundColor: isDarkMode ? '#4D4D4D' : '#FFFFFF',
              borderRadius: '10px',
              color: isDarkMode ? '#FFFFFF' : '#000000',
            }}
            value={answers[question.id] || ''}
            onChange={(e) => onInputChange(question.id, e.target.value)}
            InputProps={{
              style: { color: isDarkMode ? '#FFFFFF' : '#000000' },
            }}
          />
        </Box>
      ))}
    </Paper>
  );
};

export default QuestionSection;
