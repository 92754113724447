import React, { useContext, useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, IconButton, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as LogoLight } from "../assets/logoLight.svg";
import { ReactComponent as LogoDark } from "../assets/logo.svg";
import { ThemeContext } from "../App";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Menu } from "@mui/icons-material";

const Header = ({ onSidebarToggle }) => {
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Check for small screen
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: isDarkMode ? "#424242" : "#ffffff",
        color: isDarkMode ? "#FFFFFF" : "#000000",
        boxShadow: "none",
        borderBottom: isDarkMode ? "1px solid #616161" : "1px solid #e0e0e0",
      }}
    >
      <Toolbar
      variant="regular"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "0 16px",
          minHeight: '68px'
        }}
      >
        {isSmallScreen && (
          <IconButton
            onClick={onSidebarToggle}
            color="inherit"
            edge="start"
            sx={{ ml: 1, mr: -1 }}
            variant="contained"
          >
            {/* Sidebar Toggle Button */}
            <Menu />
          </IconButton>
        )}
        {/* If smallscreen, stack the smudge logo and app title and resize appropriately. */}
        {isSmallScreen ? (
          <Stack direction="column">
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              {isDarkMode ? (
                <LogoLight style={{ width: "96px", height: "18px" }} />
              ) : (
                <LogoDark style={{ width: "96px", height: "18px" }} />
              )}
            </Link>
            <Typography
              variant={"caption"}
              style={{
                fontWeight: "",
                textWrap: "nowrap",
                // This pad is to account for whitespace on the logo pngs.
                paddingLeft: "14px",
              }}
            >
              Balance Tools
            </Typography>
          </Stack>
        ) : (
          <>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              {isDarkMode ? (
                <LogoLight
                  style={
                    
                      { width: "160px", height: "30px" }
                  }
                />
              ) : (
                <LogoDark style={{ width: "160px", height: "30px" }} />
              )}
            </Link>
            <Typography
              variant={ "h6"}
              style={{ fontWeight: "", textWrap: "nowrap" }}
            >
              Balance Tools
            </Typography>
          </>
        )}
        <div style={{ flexGrow: 1 }}></div>
        <IconButton onClick={toggleDarkMode} color="inherit">
          {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
