import React, { useContext } from "react";
import { Typography, Box, Stack, Button } from "@mui/material";
import NavigationButton from "./NavigationButton";
import bgIntro from "../assets/bgintro.svg";
import worksheetThumbnail from "../assets/worksheet-thumbnail.png";
import worksheetPdf from "../assets/balance-worksheet.pdf"; // Add the PDF file
import introductionIconLight from "../assets/introductionOutlineLight.svg";
import introductionIconDark from "../assets/introductionOutlineDark.svg";
import { ThemeContext } from "../App";
import RootContainer from "./RootContainer";
import {
  ArrowCircleRightRounded,
  DownloadForOfflineRounded,
} from "@mui/icons-material";

const MainContent = () => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <RootContainer backgroundImage={bgIntro}>
      {/* <Typography
        variant="h4"
        style={{
          fontWeight: "bold",
          marginBottom: "24px",
          fontSize: "32px",
          lineHeight: "1.2",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={isDarkMode ? introductionIconDark : introductionIconLight}
          alt="Introduction Icon"
          style={{ width: "40px", height: "40px", marginRight: "8px" }}
        />
        Introduction
      </Typography> */}

      {/* Split View */}
      <Stack direction={{ md: "column", lg: "row" }} gap={4}>
        {/* This Site */}
        <Box
          sx={{
            border: `0.5px solid #EE6968`,
            padding: 4,
            borderRadius: 6,
            height: "min-content",
          }}
        >
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              marginBottom: "24px",
              fontSize: "32px",
              lineHeight: "1.2",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={isDarkMode ? introductionIconDark : introductionIconLight}
              alt="Introduction Icon"
              style={{ width: "40px", height: "40px", marginRight: "8px" }}
            />
            Software Evaluation Tool
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginBottom: "24px",
              fontSize: "18px",
              lineHeight: "1.6",
            }}
          >
            {/* <strong>What are these tools?</strong>
            <br /> */}
            This tool is a companion to the philosophy and content on
            Smudge.com. This tool is designed to be used by anyone working on a
            software project of any size (either individually or as part of a
            team) to help with decision-making and provoke new lines of
            questioning.
            <br />
            This approach offers a guided process to help understand our
            philosophy of solving problems.
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginBottom: "24px",
              fontSize: "18px",
              lineHeight: "1.6",
            }}
          >
            <strong>Why did we create this?</strong>
            <br />
            In software development, there is constant tension between business
            requirements, technical considerations, and user experience.
            Balancing these areas is crucial to getting a successful outcome. We
            use these tools at Smudge to sense-check our own decisions, and we
            thought other people may benefit from using them, too.
          </Typography>

          <Typography
            variant="body1"
            style={{
              marginBottom: "24px",
              fontSize: "18px",
              lineHeight: "1.6",
            }}
          >
            <strong>How do I use it?</strong>
            <br />
            1. Read through the questions in the tool.
            <br />
            2. Apply them to your own project(s) and make notes on your
            observations.
            <br />
            3. We encourage you to complete all three sections; however, you may
            want to deep-dive into particular sections if they are less familiar
            to you.
            <br />
            4. You can complete the sections in any order.
            <br />
            5. Once you complete the sections, tap "Results" in the menu bar to
            see the result.
          </Typography>
          <Typography
            variant="body1"
            style={{ fontSize: "18px", lineHeight: "1.6" }}
          >
            <strong>What happens to my data?</strong>
            <br />
            The observations you write in the tool will be stored locally in
            your browser. Your data is never transferred to the server or the
            cloud. You can clear your answers (or start a new project) at any
            time using the Clear My Answers button in the Result page.
          </Typography>
          <Button
            variant="contained"
            size="large"
            style={{
              borderRadius: "20px",
              textTransform: "capitalize",
              backgroundColor: "#EE6968",
              fontWeight: 600,
              marginBottom: 24,
              marginTop: 24,
            }}
            href="/business-considerations"
          >
            <ArrowCircleRightRounded
              style={{ marginRight: 4, marginLeft: -4 }}
            />
            Get Started
          </Button>
        </Box>
        {/* Balance Worksheet */}
        <Box
          sx={{
            border: `0.5px solid #4747AA`,
            padding: 4,
            borderRadius: 6,
            height: "min-content",
          }}
        >
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              marginBottom: "24px",
              fontSize: "32px",
              lineHeight: "1.2",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={isDarkMode ? introductionIconDark : introductionIconLight}
              alt="Introduction Icon"
              style={{ width: "40px", height: "40px", marginRight: "8px" }}
            />
            Balance Worksheet
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginBottom: "24px",
              fontSize: "18px",
              lineHeight: "1.6",
            }}
          >
            {/* <strong>What are these tools?</strong> */}
            {/* <br /> */}
            Additionally, we offer a printable worksheet that can be used as a
            collaborative tool to work through the balance process together with
            your team. This complements the digital guided process and allows
            for more in-depth discussions and brainstorming sessions.
            {/* <br />
            This site offers a guided process to help understand our philosophy
            of solving problems, we also offer an additional companion worksheet
            which can be used as a collaborative tool. */}
          </Typography>
          {/* These sections have been removed as they are no longer necessary given the rewrite above. */}

          <a
            href={worksheetPdf}
            download="balance-worksheet.pdf"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              style={{
                borderRadius: "20px",
                textTransform: "capitalize",
                backgroundColor: "#4747AA",
                fontWeight: 600,
                marginBottom: 24,
                marginTop: 24,
              }}
              startIcon={
                <img
                  src={worksheetThumbnail}
                  alt=""
                  style={{
                    borderRadius: "14px",
                    height: 48,
                    width: 44,
                    padding: 4,
                  }}
                />
              }
            >
              <DownloadForOfflineRounded
                style={{ marginRight: 4, marginLeft: -4 }}
              />
              Balance Worksheet
            </Button>
          </a>
        </Box>
      </Stack>

      {/* Navs */}
      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <NavigationButton
          direction="previous"
          text="Previous"
          link="/"
          description="Introduction"
        />
        <NavigationButton
          direction="next"
          text="Next"
          link="/business-considerations"
          description="Business Considerations - Overview"
        />
      </Box>
    </RootContainer>
  );
};

export default MainContent;
