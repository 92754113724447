import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import QuestionSection from "../components/QuestionSection";
import NavigationButton from "../components/NavigationButton";
import bgblue from "../assets/bgblue.svg";
import smudgeDotBlueIcon from "../assets/smudgeDotBlue.svg";
import AnswersContext from "../context/AnswersContext";
import RootContainer from "../components/RootContainer";

const TechnicalCapability = () => {
  const { answers, saveAnswer } = useContext(AnswersContext);

  const questions1 = [
    {
      id: "TechnicalCapability1",
      text: "Hardware: Given your budget, resources and priorities, which device(s) will you support and which can you choose to omit? Total cost of ownership is an important consideration.",
    },
    {
      id: "TechnicalCapability2",
      text: "Toolset: How will your platform choice inform your development toolset? What are the best or most appropriate tools for your chosen platform(s)?",
    },
    {
      id: "TechnicalCapability3",
      text: "Architecture: What constraints will your chosen platform(s) place on your architecture decisions?",
    },
  ];

  const questions2 = [
    {
      id: "TechnicalCapability4",
      text: "Hardware: Some devices have a broader range of applications and accessories than others.",
    },
    {
      id: "TechnicalCapability5",
      text: "Toolset: The larger and more mature the ecosystem, the easier it is to source developer tools, attract and onboard talent, and get peer support with common problems.",
    },
    {
      id: "TechnicalCapability6",
      text: "Architecture: Does your architecture take full advantage of the hardware, tools and software libraries available in the ecosystem?",
    },
  ];

  const questions3 = [
    {
      id: "TechnicalCapability7",
      text: "Hardware: By design, some devices are more secure or securable than others.",
    },
    {
      id: "TechnicalCapability8",
      text: "Toolset: Some toolsets are secured by their providers, others are reliant on communities of individual contributors.",
    },
    {
      id: "TechnicalCapability9",
      text: "Architecture: Designing an appropriate security profile with separation between components can help minimize risk.",
    },
  ];

  const handleInputChange = (id, value) => {
    saveAnswer(id, value);
  };

  return (
    <RootContainer backgroundImage={bgblue}>
      <Typography
        variant="h4"
        style={{
          fontWeight: "bold",
          marginBottom: "24px",
          fontSize: "32px",
          lineHeight: "1.2",
        }}
      >
        <img
          src={smudgeDotBlueIcon}
          alt="Smudge Dot Blue Icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
        />
        Capability
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: "24px",
          fontSize: "18px",
          lineHeight: "1.6",
        }}
      >
        Will the solution be sufficiently capable in the short term?
      </Typography>

      <QuestionSection
        title="Platform"
        description="It's important to determine the most appropriate hardware device(s) and/or software platform(s) on which to build your solution. Many elements of the user experience will be affected by your technology choices. Often these decisions will be influenced by the cultural, market or business environment."
        questions={questions1}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection
        title="Ecosystem"
        description="The scale and maturity of a technology ecosystem can impact the capability of your solution."
        questions={questions2}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection
        title="Security"
        description="For many organizations, the security profile of both deployed solutions and the tools used to build them are business-critical considerations."
        questions={questions3}
        answers={answers}
        onInputChange={handleInputChange}
      />

      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <NavigationButton
          direction="previous"
          text="Previous"
          link="/technical-considerations"
          description="Technical Considerations - Overview"
        />
        <NavigationButton
          direction="next"
          text="Next"
          link="/technical-sustainability"
          description="Technical Considerations - Sustainability"
        />
      </Box>
    </RootContainer>
  );
};

export default TechnicalCapability;
