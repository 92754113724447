import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';

// Forms the outermost container of content pages. Uses a MQ hook to handle spacing consistently.
const StyledBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  boxSizing: 'border-box',
  overflowY: 'auto',
  fontFamily: 'Arial, sans-serif',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  backgroundSize: '20%',
  backgroundAttachment: 'fixed',
}));

const RootContainer = ({ children, backgroundImage }) => {
  const smallScreen = useMediaQuery('(max-width:768px)');

  return (
    <StyledBox
      sx={{
        padding: smallScreen ? '28px' : '44px',
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      {children}
    </StyledBox>
  );
};

export default RootContainer;
