import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import QuestionSection from "../components/QuestionSection";
import NavigationButton from "../components/NavigationButton";
import bgred from "../assets/bgred.svg";
import smudgeDotRedIcon from "../assets/smudgeDotRed.svg";
import AnswersContext from "../context/AnswersContext";
import RootContainer from "../components/RootContainer";

const UXCommunication = () => {
  const { answers, saveAnswer } = useContext(AnswersContext);

  const questions1 = [
    {
      id: "UXCommunication1",
      text: "Does every interactive element offer immediate feedback to the user to acknowledge an action (e.g. do buttons respond instantly when touched)?",
    },
    {
      id: "UXCommunication2",
      text: "Be mindful of unnecessarily communicating to the user about low-level system status.",
    },
  ];

  const questions2 = [
    {
      id: "UXCommunication3",
      text: "Are you communicating only the most relevant intermittently-important information at all times (e.g. operational status, Wi-Fi signal, battery life)?",
    },
    {
      id: "UXCommunication4",
      text: "Is persistently-available information readily accessible but sufficiently unobtrusive?",
    },
  ];

  const questions3 = [
    {
      id: "UXCommunication5",
      text: "Intermittently-important information becomes critical;",
    },
    {
      id: "UXCommunication6",
      text: "A one-off event occurs that might need the user's attention or they've elected to be notified about.",
    },
    {
      id: "UXCommunication7",
      text: "Is every piece of user dialogue in your interface useful and/or necessary?",
    },
    {
      id: "UXCommunication8",
      text: "Do you use language that is understandable by everyone in your audience?",
    },
    {
      id: "UXCommunication9",
      text: "Are you matching the user's expectations about what communications they have opted to receive?",
    },
  ];

  const questions4 = [
    {
      id: "UXCommunication10",
      text: "Are the appearance and tonality of UI elements consistent with their function (e.g. are destructive actions such as deleting a file marked in red)?",
    },
    {
      id: "UXCommunication11",
      text: "If / when an action is required, are the user's next steps clearly indicated?",
    },
  ];

  const handleInputChange = (id, value) => {
    saveAnswer(id, value);
  };

  return (
    <RootContainer backgroundImage={bgred}>
      <Typography
        variant="h4"
        style={{
          fontWeight: "bold",
          marginBottom: "24px",
          fontSize: "32px",
          lineHeight: "1.2",
        }}
      >
        <img
          src={smudgeDotRedIcon}
          alt="Smudge Dot Red Icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
        />
        Communication
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: "24px",
          fontSize: "18px",
          lineHeight: "1.6",
        }}
      >
        Use different forms of communication appropriately.
      </Typography>

      <QuestionSection
        title="Interactivity"
        description="User interactions should be acknowledged with perceptible feedback in real-time; however low- level system status doesn't need to be communicated, it should be implied."
        questions={questions1}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection
        title="Relevance"
        description="Passively communicate information that is frequently accessed but intermittently important by making it easily accessible; the user can engage with it when they choose."
        questions={questions2}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection
        title="Proactivity"
        description="Proactive communication should be concise, unambiguous, and initiated only when:"
        questions={questions3}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection
        title="Clarity"
        description="UI elements should accurately and concisely denote the action or behaviour you are trying to elicit. If an action is required, the user’s options should be clearly indicated."
        questions={questions4}
        answers={answers}
        onInputChange={handleInputChange}
      />

      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <NavigationButton
          direction="previous"
          text="Previous"
          link="/user-experience-consistency"
          description="User Experience - Consistency"
        />
        <NavigationButton
          direction="next"
          text="Next"
          link="/user-experience-flexibility"
          description="User Experience - Flexibility"
        />
      </Box>
    </RootContainer>
  );
};

export default UXCommunication;
