import React, { createContext, useState } from 'react';

const AnswersContext = createContext();

export const AnswersProvider = ({ children }) => {
  const [answers, setAnswers] = useState({});

  const saveAnswer = (id, answer) => {
    setAnswers(prevAnswers => ({ ...prevAnswers, [id]: answer }));
  };

  const clearAnswers = () => {
    setAnswers({});
  };

  return (
    <AnswersContext.Provider value={{ answers, saveAnswer, clearAnswers }}>
      {children}
    </AnswersContext.Provider>
  );
};

export default AnswersContext;
