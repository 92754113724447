import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import QuestionSection from "../components/QuestionSection";
import NavigationButton from "../components/NavigationButton";
import bgyellow from "../assets/bgyellow.svg";
import smudgeDotYellowIcon from "../assets/smudgeDotYellow.svg";
import AnswersContext from "../context/AnswersContext";
import RootContainer from "../components/RootContainer";

const BusinessCapability = () => {
  const { answers, saveAnswer } = useContext(AnswersContext);

  const questions1 = [
    {
      id: "BusinessCapability1",
      text: "Do you / your team have existing domain knowledge or industry experience in this area?",
    },
    {
      id: "BusinessCapability2",
      text: "What insights do you have about the users you are building for? How well do you understand their pain points? What are the gaps?",
    },
    {
      id: "BusinessCapability3",
      text: "How well do you know your customer(s) or stakeholder(s)? Is there a good level of trust on both sides? If not, how will you build it?",
    },
  ];

  const questions2 = [
    {
      id: "BusinessCapability4",
      text: "Do you have sufficient people (designers, developers, etc) to tackle the project? What are the gaps?",
    },
    {
      id: "BusinessCapability5",
      text: "Do you have the relevant skills to tackle the project? Do you have the right blend of specialists and generalists? What are the gaps?",
    },
    {
      id: "BusinessCapability6",
      text: "What will you / your team learn from the project? How does it contribute to your professional development goals?",
    },
  ];

  const questions3 = [
    {
      id: "BusinessCapability7",
      text: "How do you plan a project? Do you plan collaboratively with your customer(s) or stakeholder(s)?",
    },
    {
      id: "BusinessCapability8",
      text: "Do you have sufficient time to tackle the project? What is driving the timeline and can you influence it?",
    },
    {
      id: "BusinessCapability9",
      text: "What other resources do you need to complete the project to a high standard (e.g. office space, equipment, etc)?",
    },
  ];

  const handleInputChange = (id, value) => {
    saveAnswer(id, value);
  };

  return (
    <RootContainer backgroundImage={bgyellow}>
      <Typography
        variant="h4"
        style={{
          fontWeight: "bold",
          marginBottom: "24px",
          fontSize: "32px",
          lineHeight: "1.2",
        }}
      >
        <img
          src={smudgeDotYellowIcon}
          alt="Smudge Dot Red Icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
        />
        Capability
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: "24px",
          fontSize: "18px",
          lineHeight: "1.6",
        }}
      >
        Do you have the capability and resources to plan and execute
        successfully? What are the gaps?
      </Typography>

      <QuestionSection
        title="Knowledge and Expertise"
        questions={questions1}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection
        title="People and Skills"
        questions={questions2}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection
        title="Planning"
        questions={questions3}
        answers={answers}
        onInputChange={handleInputChange}
      />

      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <NavigationButton
          direction="previous"
          text="Previous"
          link="/project-evaluation"
          description="Business Considerations - Project Evaluation"
        />
        <NavigationButton
          direction="next"
          text="Next"
          link="/business-processes-and-tools"
          description="Business Considerations - Processes and Tools"
        />
      </Box>
    </RootContainer>
  );
};

export default BusinessCapability;
