import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import QuestionSection from "../components/QuestionSection";
import NavigationButton from "../components/NavigationButton";
import bgblue from "../assets/bgblue.svg";
import smudgeDotBlueIcon from "../assets/smudgeDotBlue.svg";
import AnswersContext from "../context/AnswersContext";
import RootContainer from "../components/RootContainer";

const TechnicalSustainability = () => {
  const { answers, saveAnswer } = useContext(AnswersContext);

  const questions1 = [
    {
      id: "TechnicalSustainability1",
      text: "Hardware: Devices come and go, as do hardware manufacturers. There are potential risks with adopting a device or platform too early or too late in its lifecycle.",
    },
    {
      id: "TechnicalSustainability2",
      text: "Toolset: Your toolset needs to live as long as the solution is operational. As software platforms gain new capabilities, some toolsets will support them more quickly than others.",
    },
    {
      id: "TechnicalSustainability3",
      text: "Architecture: Is your architecture robust enough to adapt as your chosen platform evolves?",
    },
  ];

  const questions2 = [
    {
      id: "TechnicalSustainability4",
      text: "Hardware: Some devices have more service and support options than others, making them easier to update, repair or replace.",
    },
    {
      id: "TechnicalSustainability5",
      text: "Toolset: A good development toolset is one where every element is dependable and maintainable over the long term. Some toolsets are supported by the platform vendor, others rely on smaller companies or individual contributors.",
    },
    {
      id: "TechnicalSustainability6",
      text: "Architecture: A simple architecture of well-defined components is typically easier to support. Some components may not be supported over the lifetime of a project.",
    },
  ];

  const questions3 = [
    {
      id: "TechnicalSustainability7",
      text: "Hardware: As the business and technology landscape evolves, it's important to consider if/how your solution will support future devices.",
    },
    {
      id: "TechnicalSustainability8",
      text: "Toolset: Consider the trade-offs between using tools that offer short-term functionality vs long-term portability.",
    },
    {
      id: "TechnicalSustainability9",
      text: "Architecture: Is your architecture flexible enough to support emerging platforms? Building solutions using interoperable standards can help ensure compatibility with future technology.",
    },
  ];

  const handleInputChange = (id, value) => {
    saveAnswer(id, value);
  };

  return (
    <RootContainer backgroundImage={bgblue}>
      <Typography
        variant="h4"
        style={{
          fontWeight: "bold",
          marginBottom: "24px",
          fontSize: "32px",
          lineHeight: "1.2",
        }}
      >
        <img
          src={smudgeDotBlueIcon}
          alt="Smudge Dot Blue Icon"
          style={{ width: "24px", height: "24px", marginRight: "8px" }}
        />
        Sustainability
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: "24px",
          fontSize: "18px",
          lineHeight: "1.6",
        }}
      >
        Will the solution be sufficiently sustainable over the long-term?
      </Typography>

      <QuestionSection
        title="Life Expectancy"
        description="Consider the potential lifespan of the solution as well as the platform(s) it's being designed for."
        questions={questions1}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection
        title="Support"
        description="The ability to update and maintain a solution is often a key consideration. When technical issues occur, it's important to be able to diagnose and resolve them quickly."
        questions={questions2}
        answers={answers}
        onInputChange={handleInputChange}
      />
      <QuestionSection
        title="Portability"
        description="Which devices, platforms and/or tools might your solution need to be compatible with in the future?"
        questions={questions3}
        answers={answers}
        onInputChange={handleInputChange}
      />

      <Box display="flex" justifyContent="space-between" marginTop="24px">
        <NavigationButton
          direction="previous"
          text="Previous"
          link="/technical-capability"
          description="Technical Considerations - Capability"
        />
        <NavigationButton
          direction="next"
          text="Next"
          link="/user-experience"
          description="User Experience - Overview"
        />
      </Box>
    </RootContainer>
  );
};

export default TechnicalSustainability;
