import React from "react";
import { Typography, Box } from "@mui/material";
import NavigationButton from "../components/NavigationButton";
import bgblue from "../assets/bgblue.svg";
import smudgeDotBlueIcon from "../assets/smudgeDotBlue.svg";
import RootContainer from "../components/RootContainer";

const TechnicalConsiderations = () => (
  <RootContainer backgroundImage={bgblue}>
    <Typography
      variant="h4"
      style={{
        fontWeight: "bold",
        marginBottom: "24px",
        fontSize: "32px",
        lineHeight: "1.2",
      }}
    >
      <img
        src={smudgeDotBlueIcon}
        alt="Smudge Dot Blue Icon"
        style={{ width: "24px", height: "24px", marginRight: "8px" }}
      />
      Technical Considerations
    </Typography>
    <Typography
      variant="body1"
      style={{ marginBottom: "24px", fontSize: "18px", lineHeight: "1.6" }}
    >
      <strong>Navigating the technology trade-offs.</strong>
      <br />
    </Typography>
    <Typography
      variant="body1"
      style={{ marginBottom: "24px", fontSize: "18px", lineHeight: "1.6" }}
    >
      Every discussion around technology requirements inevitably results in
      trade-offs. Each choice that gets made along the way creates constraints
      in other areas. And since time, money and resources are limited
      commodities in every business, there are competing intentions and no easy
      answers.
    </Typography>
    <Typography
      variant="body1"
      style={{ marginBottom: "24px", fontSize: "18px", lineHeight: "1.6" }}
    >
      To help shine a light on some of the constraints and pitfalls, we've
      pulled together a list of technology considerations.
    </Typography>
    <Typography
      variant="body1"
      style={{ marginBottom: "24px", fontSize: "18px", lineHeight: "1.6" }}
    >
      This section is divided into two interrelated areas: Capability and
      Sustainability. For each one, we've explored implications for your choice
      of hardware, development toolset and architecture.
    </Typography>
    <Typography
      variant="body1"
      style={{ marginBottom: "24px", fontSize: "18px", lineHeight: "1.6" }}
    >
      Get started by clicking Next (below) or using the navigation links in the
      sidebar.
    </Typography>
    <Box display="flex" justifyContent="space-between" marginTop="24px">
      <NavigationButton
        direction="previous"
        text="Previous"
        link="/business-processes-and-tools"
        description="Business Considerations - Processes and Tools"
      />
      <NavigationButton
        direction="next"
        text="Next"
        link="/technical-capability"
        description="Technical Considerations - Capability"
      />
    </Box>
  </RootContainer>
);

export default TechnicalConsiderations;
