import React, { useState, useEffect, createContext } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/SideBar";
import MainContent from "./components/MainContent";
import ProjectEvaluation from "./pages/ProjectEvaluation";
import TechnicalConsiderations from "./pages/TechnicalConsiderations";
import BusinessConsiderations from "./pages/BusinessConsiderations";
import BusinessCapability from "./pages/BusinessCapability";
import BusinessProcessesAndTools from "./pages/BusinessProcessesAndTools";
import TechnicalCapability from "./pages/TechnicalCapability";
import TechnicalSustainability from "./pages/TechnicalSustainability";
import UXConsistency from "./pages/UXConsistency";
import UXFlexibility from "./pages/UXFlexibility";
import UXCommunication from "./pages/UXCommunication";
import UserExperience from "./pages/UserExperience";
import Result from "./pages/Result";
import { AnswersProvider } from "./context/AnswersContext";

export const ThemeContext = createContext();

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Check initial theme
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(darkThemeMq.matches);

    // Listen for theme changes
    const handleThemeChange = (e) => {
      setIsDarkMode(e.matches);
    };

    darkThemeMq.addEventListener("change", handleThemeChange);

    // Clean up listener on component unmount
    return () => {
      darkThemeMq.removeEventListener("change", handleThemeChange);
    };
  }, []);

  // Check for small screen
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
    if (!isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  const appStyle = {
    display: "flex",
    height: "calc(100vh - 64px)",
    marginTop: "64px",
    backgroundColor: isDarkMode ? "#2E2E2E" : "#f4f3f1",
    color: isDarkMode ? "#FFFFFF" : "#000000",
    overflow: "hidden",
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      <AnswersProvider>
        <Header onSidebarToggle={toggleSidebar} />
        <div style={appStyle}>
          {/* Smallscreen sidebar mgmt */}
        {!isSidebarCollapsed && isSmallScreen && (
            <div
              onClick={toggleSidebar}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 998,
              }}
            />
          )}
          {/* Standard sidebar mgmt */}
          <div
            style={{
              width: isSmallScreen ? (isSidebarCollapsed ? "0px" : "300px") : "300px",
              position: isSmallScreen ? "fixed" : "relative",
              top: isSmallScreen ? "64px" : "0",
              left: isSmallScreen ? (isSidebarCollapsed ? "-250px" : "0") : "0",
              flexShrink: 0,
              height: "100%",
              overflowY: "auto",
              transition: "width 0.3s, left 0.3s",
              backgroundColor: isDarkMode ? "#3B3B3B" : "#f4f3f1",
              zIndex: 999, // Above the main content but below the backdrop
            }}
          >
            <Sidebar />
          </div>
          <div
            style={{
              width: "1px",
              backgroundColor: isDarkMode ? "#4D4D4D" : "#d3d3d3",
            }}
          ></div>{" "}
          {/* Divider */}
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/project-evaluation" element={<ProjectEvaluation />} />
            <Route
              path="/technical-considerations"
              element={<TechnicalConsiderations />}
            />
            <Route
              path="/business-considerations"
              element={<BusinessConsiderations />}
            />
            <Route
              path="/business-capability"
              element={<BusinessCapability />}
            />
            <Route
              path="/business-processes-and-tools"
              element={<BusinessProcessesAndTools />}
            />
            <Route
              path="/technical-capability"
              element={<TechnicalCapability />}
            />
            <Route
              path="/technical-sustainability"
              element={<TechnicalSustainability />}
            />
            <Route path="/user-experience" element={<UserExperience />} />
            <Route
              path="/user-experience-consistency"
              element={<UXConsistency />}
            />
            <Route
              path="/user-experience-communication"
              element={<UXCommunication />}
            />
            <Route
              path="/user-experience-flexibility"
              element={<UXFlexibility />}
            />
            <Route path="/result" element={<Result />} />
          </Routes>
        </div>
      </AnswersProvider>
    </ThemeContext.Provider>
  );
}

export default App;
